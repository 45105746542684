export const loginAction = args => {
	return {
		type: 'login',
		args
	};
};

export const appleLoginAction = args => {
	return {
		type: 'apple-login',
		args
	};
};

export const googleLoginAction = args => {
	return {
		type: 'google-login',
		args
	};
};

export const facebookLoginAction = args => {
	return {
		type: 'facebook-login',
		args
	};
};

export const loginSuccessAction = (customer, message) => {
	return {
		type: 'login-success',
		customer,
		message
	};
};

export const logoutAction = () => {
	return {
		type: 'logout'
	};
};

export const logoutSuccessAction = () => {
	return {
		type: 'logout-success'
	};
};

export const authErrorAction = error => {
	return {
		type: 'auth-error',
		error
	};
};

export const clearAuthMessagesAction = () => {
	return {
		type: 'auth-clear-messages'
	};
};

export const showLoginModal = bool => {
	return {
		type: 'show-login-modal',
		bool
	};
};

export const showCheckoutModal = bool => {
	return {
		type: 'show-checkout-login-modal',
		bool
	};
};
