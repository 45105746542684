const initialState = {
	error: '',
	customer: null,
	message: '',
	showLoginModal: false,
	showCheckoutLoginModal: false,
	loading: false
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'login':
			state = { ...state, error: '', loading: true };
			break;
		case 'apple-login':
			state = { ...state, error: '', loading: true };
			break;
		case 'google-login':
			state = { ...state, error: '', loading: true };
			break;
		case 'facebook-login':
			state = { ...state, error: '', loading: true };
			break;
		case 'login-success':
			state = { ...state, customer: action.customer, message: action.message, loading: false };
			break;
		case 'logout':
			state = { ...state };
			break;
		case 'logout-success':
			state = { ...state };
			break;
		case 'auth-error':
			state = { ...state, error: action.error, loading: false };
			break;
		case 'auth-clear-messages':
			state = { ...state, message: '', error: '', loading: false };
			break;
		case 'show-login-modal':
			state = { ...state, showLoginModal: action.bool };
			break;
		case 'show-checkout-login-modal':
			state = { ...state, showCheckoutLoginModal: action.bool };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default authReducer;
