const initialState = {
	showAddToWishlist: false,
	wishlistProductId: 0,
	handledProduct: {
		productId: 0,
		active: true
	}
};

const layoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'add-to-wishlist':
			state = { ...state, showAddToWishlist: true, wishlistProductId: action.productId };
			break;
		case 'close-add-to-wishlist':
			state = { ...state, showAddToWishlist: false, wishlistProductId: action.productId };
			break;
		case 'update-handled-product':
			state = { ...state, handledProduct: action.handledProduct };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default layoutReducer;
