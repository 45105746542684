import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearAuthMessagesAction } from '~/store/auth/actions';
import { clearRegisterMessagesAction, registerCustomerAction } from '~/store/register/actions';

import LoadingSpinner from '~/components/common/LoadingSpinner';
import { notifyError } from '~/components/common/ToastMessages';

import eyeOffIcon from '~/images/eye-off.svg';
import eyeIcon from '~/images/eye.svg';

import { GoogleOAuthProvider } from '@react-oauth/google';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { validateGuestUserEmail } from '~/components/checkout/utils/checkout_step_1';
import AppleLoginButton from '~/components/login/AppleLoginButton';
import GoogleLoginButton from '~/components/login/GoogleLoginButton';

const Register = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isShownRegister1, setIsShownRegister1] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [nameInput, setNameInput] = useState('');
	const [lastnameInput, setLastnameInput] = useState('');
	const [emailInput, setEmailInput] = useState('');
	const [passInput, setPassInput] = useState('');
	const [phoneInput, setPhoneInput] = useState('');
	const [visiblePassword, setVisiblePassword] = useState(false);

	const [passStrong, setPassStrong] = useState('');

	const loading = useSelector(state => state.registerReducer.loading);
	const authError = useSelector(state => state.registerReducer.error);
	const successMessage = useSelector(state => state.registerReducer.message);
	const successMessage_auth = useSelector(state => state.authReducer.message);
	const dispatch = useDispatch();

	const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	const openRegister1 = event => {
		setIsShownRegister1(current => !current);
	};

	//const [countries, setCountries] = useState([]);
	//const [countryId, setCountryId] = useState(0);

	//const [regions, setRegions] = useState([]);
	//const [regionId, setRegionId] = useState(0);
	//const [selectedRegion, setSelectedRegion] = useState();

	//const [stores, setStores] = useState([]);
	//const [storeId, setStoreId] = useState(0);

	const [customer, setCustomer] = useState({});
	//const [address, setAddress] = useState({});
	const [selectedGender, setSelectedGender] = useState('woman');

	//const [day, setDay] = useState(0);
	//const [month, setMonth] = useState('');
	//const [year, setYear] = useState(0);

	const [isHuman, setHuman] = useState(true);

	const handleCustomerField = (e, field) => {
		if (e.target == undefined) {
			// is custom select
			customer[field] = e.value;
		} else {
			customer[field] = e.target.value;
		}

		setCustomer(customer);
	};

	//const handleAddressField = (e, field) => {
	//    if (e.target == undefined) {
	//        // is custom select
	//        address[field] = e.value;
	//    } else {
	//        address[field] = e.target.value;
	//    }

	//    setAddress(address);
	//};

	const handleCustBoolField = (e, field) => {
		customer[field] = e.target.checked;
		setCustomer(customer);
	};

	//const convertListToSelectOptions = (items) => {
	//    if (items == null || items.length == 0) return [];

	//    let options = [];
	//    items.map((item) => options.push({ value: item.id, label: item.nm }));
	//    return options;
	//}

	useEffect(() => {
		//api_getCountries()
		//    .then(json => setCountries(convertListToSelectOptions(json.items)));

		// terms and newsletter are accepted by default (by customer)
		customer['popt'] = true;
		customer['rnl'] = true;
		setCustomer(customer);
	}, []);

	//useEffect(() => {
	//    if (countryId == 0) return;

	//    api_getCountryRegions(countryId)
	//        .then(json => setRegions(convertListToSelectOptions(json.items)));
	//}, [countryId]);

	//useEffect(() => {
	//    api_findTakeAwayStoresGrouped(countryId, regionId)
	//        .then(json => setStores(json.items));
	//}, [countryId, regionId]);

	const handleGenderChange = e => {
		if (e.target.value == 'man') {
			customer.gd = 1;
		} else if (e.target.value == 'woman') {
			customer.gd = 2;
		} else if (e.target.value == 'other') {
			customer.gd = 3;
		} else if (e.target.value == 'noDisclose') {
			customer.gd = 4;
		} else {
			customer.gd = 0;
		}
		setSelectedGender(e.target.value);
		setCustomer(customer);
	};

	const onCaptchaChange = value => {
		setHuman(true);
		//console.log("Captcha value:", value);
	};
	const handleFormSubmit = e => {
		e.preventDefault();

		if (customer.fn == null || customer.fn.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (customer.ln == null || customer.ln.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (customer.eml == null || customer.eml.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (customer.mob == null || customer.eml.length < 17) {
			notifyError(t('Mobile format error'));
			return;
		}

		if (passStrong.length < 6) {
			notifyError(t('password_strong'));
			return;
		}

		if (!isHuman) {
			notifyError('Please complete the CAPTCHA to proceed');
			return;
		}

		if (!validateGuestUserEmail(customer.eml)) {
			notifyError(t('validation_email'));
			return;
		}

		//if (!customer.at) {
		//    notifyError("Please accept our terms & conditions first");
		//    return;
		//}

		//if (day == 0 || month.length == 0 || year == 0) {
		//    notifyError("Please fill you birth date");
		//    return;
		//}

		//const birthDate = new Date(year, month, day);
		// console.log(birthDate);

		//customer['dob'] = birthDate;
		//console.log(customer);

		//address.tl = "Default";
		// Hardcode greece until selects are fixed
		//address.cnid = 1;
		//customer.address = address;

		//console.log(customer);

		dispatch(registerCustomerAction(customer));
	};

	// const formFilled = (cust) => {
	//     if (!cust.at) return false;
	//     /*if (day == 0 || month.length == 0 || year == 0) return false;*/
	//     if (cust.ln == null || cust.ln.length == 0) return false;
	//     if (cust.fn == null || cust.fn.length == 0) return false;
	//     if (cust.mob == null || cust.mob.length == 0) return false;
	//     if (cust.eml == null || cust.eml.length == 0) return false;
	//     if (cust.psw == null || cust.psw.length == 0) return false;
	//     if (customer.gd == 0) return false;

	//     return true;
	// }

	// const checkEmptyFields = (e) => {
	//     if (customer.fn == null || customer.fn.length !== 10) {
	//         notifyError("Firstname is required with 10");
	//         return;
	//     }
	//     if (customer.ln == null || customer.ln.length == 0) {
	//        setLastnameInput("error");
	//     }
	//     if (customer.eml == null || customer.eml.length == 0) {
	//        setEmailInput("error");
	//     }
	//     if (customer.pass == null || customer.pass.length == 0) {
	//        setPassInput(" error");
	//     }
	// }

	useEffect(() => {
		if (authError.length > 0) {
			notifyError(t('email_already_exists'), () => {
				dispatch(clearRegisterMessagesAction());
			});
		}
	});

	useEffect(() => {
		if (successMessage.length > 0) {
			if (successMessage === 'Google Registration completed' || successMessage === 'Facebook Registration completed') return;
			dispatch(clearRegisterMessagesAction());

			navigate('/registrationcheckemail');
		}
	}, [successMessage]);

	useEffect(() => {
		if (successMessage_auth.length > 0) {
			// Navigate to home page after auto-login
			dispatch(clearAuthMessagesAction());
			navigate('/');
		}
	}, [successMessage_auth]);

	// birth date select fields
	//const [daySelect, setDaySelect] = useState(0);
	//const [monthSelect, setMonthSelect] = useState(0);
	//const [yearSelect, setYearSelect] = useState(0);

	// initialize birth date fields
	//useEffect(() => {
	//    if (daySelect === 0) {
	//        setDaySelect(1);
	//    }

	//    if (monthSelect === 0) {
	//        setMonthSelect(1);
	//    }

	//    if (yearSelect === 0) {
	//        setYearSelect(1);
	//    }
	//}, [daySelect, monthSelect, yearSelect]);

	//const resetRegionsSelect = () => {
	//    setRegions([])
	//    setSelectedRegion(null);
	//};

	// set days dropdown select
	//let days = [];

	//daysList.map((item) => {
	//    days.push({
	//        value: item,
	//        label: item
	//    });
	//});

	//// set months dropdown select
	//let months = [];

	//monthsList.map((item, index) => {
	//    months.push({
	//        value: index,
	//        label: item
	//    });
	//});

	//// set years dropdown select
	//let years = [];

	//yearsList.map((item) => {
	//    years.push({
	//        value: item,
	//        label: item
	//    });
	//  //let days = [];

	//daysList.map((item) => {
	//    days.push({
	//        value: item,
	//        label: item
	//    });
	//});

	//// set months dropdown select
	//let months = [];

	//monthsList.map((item, index) => {
	//    months.push({
	//        value: index,
	//        label: item
	//    });
	//});

	//// set years dropdown select
	//let years = [];

	//yearsList.map((item) => {
	//    years.push({
	//        value: item,
	//        label: item
	//    });
	//});

	return (
		<>
			{!isShownRegister1 && (
				<div>
					<div className="popup-register">
						{/* <BackNavigation ShowCloseIcon={true} PageToNavigate={"login"} />*/}
						{/*<button type="button" onClick={openRegister1} className="flex-col back-btn"><img style={{ transform: "rotate(180deg)" }} transfrom src={back_arrow} alt="back-register" /><span>{t("back")}</span></button>*/}
						<div id="account-info-wrapper" className="narrow-wrapper">
							<form id="account-info" onSubmit={handleFormSubmit}>
								<div className="grid-col inputs">
									<div className={'form-input text-label ' + nameInput}>
										<input
											id="firstname"
											type="text"
											name="your-firstname"
											required
											placeholder={t('first_name')}
											defaultValue={customer.fn}
											onChange={e => handleCustomerField(e, 'fn')}
										/>
										<label htmlFor="your-firstname">{t('first_name')}*</label>
									</div>

									<div className={'form-input text-label ' + lastnameInput}>
										<input
											id="lastname"
											type="text"
											name="your-lastname"
											required
											placeholder={t('last_name')}
											defaultValue={customer.ln}
											onChange={e => handleCustomerField(e, 'ln')}
										/>
										<label htmlFor="your-lastname">{t('last_name')}*</label>
									</div>

									<div className={'form-input text-label ' + emailInput}>
										<input
											id="email"
											type="email"
											name="your-email"
											required
											placeholder="email"
											defaultValue={customer.eml}
											onChange={e => handleCustomerField(e, 'eml')}
										/>
										<label htmlFor="your-email">Email*</label>
									</div>

									<div className={passStrong ? 'form-input text-label' : 'form-input text-label' + passInput}>
										<input
											className={passStrong.length < 6 ? 'no-validate' : null}
											id="pass"
											type={visiblePassword ? 'text' : 'password'}
											name="your-pass"
											required
											placeholder="password"
											value={passStrong}
											onChange={e => {
												handleCustomerField(e, 'psw');
												setPassStrong(e.target.value);
											}}
										/>

										<label htmlFor="your-pass">Password*</label>
										<span id="togglePassVisibillity" className="pass-visibillity-icon" onClick={() => setVisiblePassword(!visiblePassword)}>
											<img src={visiblePassword ? eyeOffIcon : eyeIcon} alt="eye-show-pass" />
										</span>
									</div>

									<div className="phone-wrapper">
										<span className="label">Mobile*</span>
										<PhoneInput
											country={'gr'}
											regions={'europe'}
											countryCodeEditable={true}
											specialLabel="Mobile**"
											placeholder="Mobile*"
											value={phoneInput}
											required
											onChange={(value, country, e, formattedValue) => {
												setPhoneInput(formattedValue);
												handleCustomerField({ value: formattedValue ?? '' }, 'mob');
											}}
										/>
									</div>
									<div className="form-input text-label">
										<input
											type="date"
											name="date"
											defaultValue={customer.dob}
											onChange={e => handleCustomerField(e, 'dob')}
											placeholder="birthdate"
										/>
										<label htmlFor="date">{t('date_of_birth')}</label>
									</div>
								</div>

								<div className="grid-col gender-sec radio">
									<label className="radio-label" htmlFor="woman">
										<input
											type="radio"
											id="woman"
											name="sex"
											value="woman"
											checked={selectedGender === 'woman'}
											onChange={e => handleGenderChange(e)}
										/>{' '}
										{t('gender_woman')}
									</label>
									<label className="radio-label" htmlFor="man">
										<input
											type="radio"
											id="man"
											name="sex"
											value="man"
											checked={selectedGender === 'man'}
											onChange={e => handleGenderChange(e)}
										/>{' '}
										{t('gender_man')}
									</label>
									<label className="radio-label" htmlFor="other">
										<input
											type="radio"
											id="other"
											name="sex"
											value="other"
											checked={selectedGender === 'other'}
											onChange={e => handleGenderChange(e)}
										/>{' '}
										{t('gender_other')}
									</label>
									<label className="radio-label" htmlFor="noDisclose">
										<input
											type="radio"
											id="noDisclose"
											name="sex"
											value="noDisclose"
											checked={selectedGender === 'noDisclose'}
											onChange={e => handleGenderChange(e)}
										/>{' '}
										{t('gender_rather_not_say')}
									</label>
								</div>

								{/*    <span className="red">*</span>*/}
								{/*    <input type="tel" name="mobile" placeholder={t("phone")} pattern="69[0-9]{8}" required*/}
								{/*        defaultValue={customer.mob} onChange={(e) => handleCustomerField(e, "mob")} />*/}
								{/*<label htmlFor="mobile"></label>*/}

								{/*<div id="countriesSelect" className="custom-select">*/}
								{/*    <span className="red">*</span>*/}

								{/*    <Select*/}
								{/*        id="countrySelect"*/}
								{/*        placeholder="ΧΩΡΑ"*/}
								{/*        options={countries}*/}
								{/*        onChange={(e) => {*/}
								{/*            setCountryId(e.value);*/}
								{/*            handleAddressField(e, "cnid");*/}
								{/*            resetRegionsSelect();*/}
								{/*        }}*/}
								{/*        onMenuClose={() => { unFocusSelect('countrySelect') }}*/}
								{/*        onMenuOpen={() => { focusSelect('countrySelect') }}*/}
								{/*        styles={selectStyles}*/}
								{/*    />*/}
								{/*</div>*/}

								{/*<div id="regionsSelect" className="custom-select">*/}
								{/*    <span className="red">*</span>*/}

								{/*    <Select*/}
								{/*        id="regionSelect"*/}
								{/*        placeholder="ΝΟΜΟΣ"*/}
								{/*        options={regions}*/}
								{/*        onChange={(e) => {*/}
								{/*            setRegionId(e.value);*/}
								{/*            handleAddressField(e, "crid");*/}
								{/*            setSelectedRegion({ value: e.value, label: e.label })*/}
								{/*        }}*/}
								{/*        onMenuClose={() => { unFocusSelect('regionSelect') }}*/}
								{/*        onMenuOpen={() => { focusSelect('regionSelect') }}*/}
								{/*        styles={selectStyles}*/}
								{/*        defaultValue={selectedRegion}*/}
								{/*    />*/}
								{/*</div>*/}

								{/*<label>*/}
								{/*    <span className="red">*</span>*/}
								{/*    <input type="text" name="address" placeholder="ΔΙΕΥΘΥΝΣΗ" required*/}
								{/*        defaultValue={address.adl} onChange={(e) => handleAddressField(e, "adl")} />*/}
								{/*</label>*/}

								{/*<label>*/}
								{/*    <span className="red">*</span>*/}
								{/*    <input type="text" name="city" placeholder="ΠΟΛΗ" required*/}
								{/*        defaultValue={address.ct} onChange={(e) => handleAddressField(e, "ct")} />*/}
								{/*</label>*/}

								{/*<label>*/}
								{/*    <span className="red">*</span>*/}
								{/*    <input type="number" name="postcode" placeholder="TK" required*/}
								{/*        defaultValue={address.pc} onChange={(e) => handleAddressField(e, "pc")} />*/}
								{/*</label>*/}

								{/*<div id="storesSelect" className="custom-select">*/}
								{/*    <span className="red">*</span>*/}

								{/*    <Select*/}
								{/*        id="storeSelect"*/}
								{/*        placeholder="ΚΑΤΑΣΤΗΜΑ"*/}
								{/*        options={stores}*/}
								{/*        onChange={(e) => {*/}
								{/*            setStoreId(e.value);*/}
								{/*            handleCustomerField(e, "tsid");*/}
								{/*        }}*/}
								{/*        onMenuClose={() => { unFocusSelect('storeSelect') }}*/}
								{/*        onMenuOpen={() => { focusSelect('storeSelect') }}*/}
								{/*        styles={selectStyles}*/}
								{/*    />*/}
								{/*</div>*/}

								{/*<div className="birth-date">*/}
								{/*    <p style={{ textAlign: "center" }}><span style={{ color: "#ff0000" }}>* </span>BIRTH DATE</p>*/}

								{/*    <div className="birth-date-options custom-select">*/}
								{/*        <Select*/}
								{/*            id="daySelect"*/}
								{/*            placeholder="D"*/}
								{/*            options={days}*/}
								{/*            onMenuClose={() => { unFocusSelect('daySelect') }}*/}
								{/*            onMenuOpen={() => { focusSelect('daySelect') }}*/}
								{/*            styles={selectStyles}*/}
								{/*            className="select-day"*/}
								{/*            onChange={(e) => setDay(e.value)}*/}
								{/*            defaultValue={day}*/}
								{/*        />*/}

								{/*        <Select*/}
								{/*            id="monthSelect"*/}
								{/*            placeholder="M"*/}
								{/*            options={months}*/}
								{/*            onMenuClose={() => { unFocusSelect('monthSelect') }}*/}
								{/*            onMenuOpen={() => { focusSelect('monthSelect') }}*/}
								{/*            styles={selectStyles}*/}
								{/*            className="select-month"*/}
								{/*            onChange={(e) => setMonth(e.value)}*/}
								{/*            defaultValue={month}*/}
								{/*        />*/}

								{/*        <Select*/}
								{/*            id="yearSelect"*/}
								{/*            placeholder="Y"*/}
								{/*            options={years}*/}
								{/*            onMenuClose={() => { unFocusSelect('yearSelect') }}*/}
								{/*            onMenuOpen={() => { focusSelect('yearSelect') }}*/}
								{/*            styles={selectStyles}*/}
								{/*            className="select-year"*/}
								{/*            onChange={(e) => setYear(e.value)}*/}
								{/*            defaultValue={year}*/}
								{/*        />*/}

								{/*    </div>*/}
								{/*</div>*/}

								<div className="accept-sec grid-col">
									<label className="acceptance">
										<input type="checkbox" name="acceptance-newsletter" onChange={e => handleCustBoolField(e, 'rnl')} />
										<span className="input-label_newsletter" dangerouslySetInnerHTML={{ __html: t('accept_newsletter') }}></span>
									</label>

									<label className="acceptance">
										<input type="checkbox" name="accept-club" onChange={e => handleCustBoolField(e, 'popt')} />
										<span className="input-label">
											<span dangerouslySetInnerHTML={{ __html: t('policy_acceprt_terms') }}></span>
										</span>
									</label>
								</div>

								<div className="captcha-wrapper">
									{/*<ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                                    onChange={onCaptchaChange}
                                />*/}
								</div>

								<button type="submit" className="submit arrow-button black-arrow-button">
									{t('register')}
								</button>
							</form>

							<LoadingSpinner isLoading={loading} />
						</div>

						<div className="login-alter register-alter">
							<span>{t('register_alternative')}</span>
							<div className="flex-col">
								{/* <FacebookLoginButton /> */}
								<GoogleOAuthProvider clientId={googleClientId}>
									<GoogleLoginButton />
								</GoogleOAuthProvider>
								<AppleLoginButton />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Register;
