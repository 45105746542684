import axios from 'axios';
import UserSession from '~/backend/user_session';

//i18n
import i18n from '~/i18n';

import { createBrowserHistory } from 'history';

const axiosApi = axios.create({
	baseURL: process.env.REACT_APP_BIZWEB_API_URL,
	crossdomain: true,
	headers: {
		Accept: 'application/json'
	}
});

const parseApiError = error => {
	if (error?.response) {
		// Pass server response
		if (error.response.data) {
			if (error.response.data.msg) {
				return error.response.data.msg;
			}

			if (error.response.data.title) {
				return error.response.data.title;
			}

			return error.response.data;
		}

		var message = '';
		switch (error.response.status) {
			case 404:
				message = i18n.t('api_err_404');
				break;
			case 500:
				message = i18n.t('api_err_500');
				break;
			case 401:
				message = i18n.t('api_err_401');
				break;
			default:
				message = error.message;
				break;
		}

		return message;
	}

	if (error?.message) {
		return error.message;
	}

	return error;
};

axiosApi.interceptors.request.use(
	config => {
		// Apply localised API url
		config.baseURL = apiBaseUrl();

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

var refreshRetry = false;

axiosApi.interceptors.response.use(
	response => response,
	async error => {
		const originalConfig = error.config;

		try {
			if (
				error?.response?.status === 401 &&
				!refreshRetry &&
				!error.config.url.endsWith('/customers/login') &&
				!error.config.url.endsWith('/customers/refreshtoken')
			) {
				refreshRetry = true;

				// Refresh bearer token
				try {
					const rs = await axiosApi.post('/account/refreshtoken', {
						at: UserSession.getAccessToken(),
						rt: UserSession.getRefreshToken()
					});

					if (rs.data) {
						UserSession.initWithJson(rs.data);
					} else {
						refreshRetry = false;

						// *** Redirect to login page ***
						createBrowserHistory().push('/logout');
						window.location.reload();

						return Promise.reject(error);
					}
				} catch (e1) {
					refreshRetry = false;

					// *** Redirect to login page ***
					createBrowserHistory().push('/logout');
					window.location.reload();

					return Promise.reject(e1);
				}

				// Replace bearer of original request and re-run
				originalConfig.headers['Authorization'] = 'Bearer ' + UserSession.getAccessToken();

				return axiosApi(originalConfig);
			} else {
				refreshRetry = false;
				return Promise.reject(parseApiError(error));
			}
		} catch (e) {
			refreshRetry = false;
			return Promise.reject(e);
		}
	}
);

function composeAxiosConfig(useAuth, signal, doNotParseError) {
	var config = { doNotParseError };

	// FIX:
	// Previously: We were sending the auth token only if useAuth was true. This was causing issues when the user was logged in and we called public APIs. (AllowAnonymous in C# controllers)
	// The api was treating the request as if it was coming from a guest user and no customerId was used in the business logic on the server.
	// AllowAnonymous does not mean that the request MUST be made by a guest user. It means that the request can be made by either logged in users or guests.
	// If the user is logged in, we need to know it even if the API is public.
	// We relied on the client to send a customerId in the request body which is not a good practice.
	// There is no reason to not send the auth token for all requests if the user is logged in.
	//
	// Now: We ignore the useAuth flag and send the auth token if there is one.

	var authtoken = UserSession.getAccessToken();
	if (authtoken) {
		//console.debug("Axios Bearer: " + UserSession.getAccessToken())

		config = {
			...config,
			headers: {
				Authorization: 'Bearer ' + UserSession.getAccessToken()
			}
		};
	}

	if (signal) {
		config.signal = signal;
	}

	return config;
}

export async function get(url, cached = false, useAuth = true, signal = null) {
	if (!cached) {
		url = url + (url.includes('?') ? '&' : '?') + 'v=' + Date.now();
	}

	//console.debug("Axios GET:" + url)

	return axiosApi.get(url, composeAxiosConfig(useAuth, signal)).then(response => response.data);
}

export async function post(url, data, useAuth = true, signal = null) {
	//console.debug("Axios POST:" + url)

	return axiosApi.post(url, { ...data }, composeAxiosConfig(useAuth, signal)).then(response => response.data);
}

export async function put(url, data, useAuth = true) {
	console.debug('Axios PUT:' + url);

	return axiosApi.put(url, { ...data }, composeAxiosConfig(useAuth)).then(response => response.data);
}

export async function del(url, useAuth = true) {
	console.debug('Axios DELETE:' + url);

	return axiosApi.delete(url, composeAxiosConfig(useAuth)).then(response => response.data);
}

export const apiBaseUrl = () => {
	const lang = UserSession.getActiveLanguage();
	// return process.env.REACT_APP_BIZWEB_API_URL + '/' + lang;
	return process.env.REACT_APP_BIZWEB_API_URL + '/el';
};

export function onDataGridRowUpdatingMergeUnchanged(e) {
	e.newData = { ...e.oldData, ...e.newData };
}

export function fileUploaderHeaders() {
	return {
		Authorization: 'Bearer ' + UserSession.getAccessToken()
	};
}
