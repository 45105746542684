//From Small Size
export function convertImageUrl_S_to_L(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_S.', '_L.');
}

export function convertImageUrl_S_to_M(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_S.', '_M.');
}

export function convertImageUrl_S_to_F(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_S.', '.');
}

//From Medium Size
export function convertImageUrl_M_to_S(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_M.', '_S.');
}

export function convertImageUrl_M_to_L(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_M.', '_L.');
}

export function convertImageUrl_M_to_F(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_M.', '.');
}

//From Large Size
export function convertImageUrl_L_to_S(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_L.', '_S.');
}

export function convertImageUrl_L_to_M(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_L.', '_M.');
}

export function convertImageUrl_L_to_F(url) {
	if (url == null || url.length == 0) return null;

	return url.replace('_L.', '.');
}

export function product_link(url) {
	if (url == null || url.length == 0) return null;

	return '/products/' + url;
}
