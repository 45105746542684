import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { api_getCustomMessageByCode } from '~/backend/api_calls';

const InfoMessage = ({ className, code, hasCloseButton, style }) => {
	const [message, setMessage] = useState();

	const showMessage = msg => {
		setMessage(msg);
	};

	useEffect(() => {
		api_getCustomMessageByCode(code)
			.then(json => {
				let closeDate = localStorage.getItem(`${code}_close_date`);

				if (closeDate) {
					const validDate = new Date(closeDate);
					validDate.setDate(validDate.getDate() + 1);

					if (validDate < new Date()) {
						showMessage(json);
					}
				} else {
					showMessage(json);
				}
			})
			.catch(error => console.error(error));
	}, []);

	const handleCloseMessage = () => {
		setMessage(null);
		localStorage.setItem(`${code}_close_date`, new Date());
	};

	return (
		<>
			{message && (
				<div className={className} style={style}>
					{/* <span>{message.bd}</span> */}
					<div
						className="top-message"
						dangerouslySetInnerHTML={{
							__html: message?.bd
						}}
					/>
					{hasCloseButton && (
						<button type="button" onClick={() => handleCloseMessage()}>
							<svg xmlns="http://www.w3.org/2000/svg" width="13.435" height="13.435" viewBox="0 0 13.435 13.435">
								<g transform="translate(-1181.793 -188.793)">
									<line x2="12.021" y2="12.021" transform="translate(1182.5 189.5)" fill="none" stroke="#959595" strokeWidth="2" />
									<line x1="12.021" y2="12.021" transform="translate(1182.5 189.5)" fill="none" stroke="#959595" strokeWidth="2" />
								</g>
							</svg>
						</button>
					)}
				</div>
			)}
		</>
	);
};

InfoMessage.propTypes = {
	className: PropTypes.string,
	code: PropTypes.string,
	hasCloseButton: PropTypes.bool,
	style: PropTypes.object
};

export default InfoMessage;
